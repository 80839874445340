// src/i18n.js 或类似文件
import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default || locales(key);
    }
  });
  return messages;
}
const savedLocale = localStorage.getItem("app-locale") || "zh";

const i18n = createI18n({
  legacy: false,
  locale: savedLocale, // 设置默认语言
  fallbackLocale: "zh", // 如果没有可用的翻译，则退回到该语言
  messages: loadLocaleMessages(),
});

export default i18n;
