/**
 * userRequest模块接口列表
 */

import axios from "@/util/http"; // 导入http中创建的axios实例
import qs from "qs";
import cookies from "js-cookie";
import util from "@/util/util";

let Base64 = require("js-base64").Base64;

const repairRequest = {
  //上传报修信息
  addRepair(repairAddRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/addRepair`, repairAddRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取安装信息的点位列表
  getActivityInstallation(activityInstallationROPage, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/getActivityInstallation`,
        activityInstallationROPage,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //提交点位安装图片
  submitStoreLocationSingle(activityInstallationSingleSubmitRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/submitStoreLocationSingle`,
        activityInstallationSingleSubmitRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //提交点位安装图片
  submitStoreLocation(installtionSubmitRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/submitStoreLocation`, installtionSubmitRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  uploadActivityInstallationPhoto(activityInstallationPhotoUploadRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/uploadActivityInstallationPhoto`,
        activityInstallationPhotoUploadRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //删除点位照片
  deleteInstallationPhoto(uploadDeleteRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/deleteInstallationPhoto`, uploadDeleteRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //忽略点位照片
  ignoreActivityInstallationUpload(ignoreActivityInstallationUploadRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/ignoreActivityInstallationUpload`,
        ignoreActivityInstallationUploadRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //新增位照片
  addInstallationPhoto(approvedAddActivityInstallationUploadRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/addInstallationPhoto`,
        approvedAddActivityInstallationUploadRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //验收确认提交
  submitAcceptResult(activityInstallationSubmitAcceptRO, retry) {
    debugger;
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/submitAcceptResult`,
        activityInstallationSubmitAcceptRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取所有的报修列表
  getRepair(repairSelectRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/getRepair`, repairSelectRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //预约报修
  repairSchedule(repairRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/repairSchedule`, repairRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //报修指派
  assignRepair(assignRepairRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/assignRepair`, assignRepairRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取报修信息
  selectRepairById(repairIdRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/selectRepairById`, repairIdRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //提交维修信息
  submitRepairInfo(submitRepairRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/submitRepairInfo`, submitRepairRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //删除上传维修的照片
  deleteRepairPhoto(uploadDeleteRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/deleteRepairPhoto`, uploadDeleteRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //报修验收确认提交
  submitAcceptInfo(submitAcceptRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/submitAcceptInfo`, submitAcceptRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取所有的验收状态
  getInstallationResult(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,

        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getInstallationResult`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取活动信息
  getActivity(activityId, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    var param = { activityId: activityId };
    param = util.parseParams(param);
    return axios
      .get(`/api/activity/get?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取所有供应商
  getVendorAll(retry) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getVendorAll`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //根据报修点位获取活动
  selectActivityInfoByStoreLocationId(repairActivityRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/selectActivityInfoByStoreLocationId`,
        repairActivityRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //根据报修点位获取活动
  getStoreByActivityVendor(storeActivityVendorRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/store/getStoreByActivityVendor`,
        storeActivityVendorRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getSignature(directory) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };

    var param = { directory: directory };
    param = util.parseParams(param);
    return axios
      .get(`/api/authorization/getSignature?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  cancelRepair(repairCancelRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/cancelRepair`, repairCancelRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
export default repairRequest;
