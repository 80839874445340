/**
 * userRequest模块接口列表
 */

import axios from "@/util/http"; // 导入http中创建的axios实例
import qs from "qs";
import cookies from "js-cookie";
import util from "@/util/util";

let Base64 = require("js-base64").Base64;

const vendorRequest = {
  getVendorUserPage(vendorUserPageROPageInfo, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/getVendorUserPage`, vendorUserPageROPageInfo, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getVendorUser(vendorId, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    var param = { vendorId: vendorId };
    param = util.parseParams(param);

    return axios
      .get(`/api/vendor/getVendorUser?` + param, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getVendorAll(retry) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getVendorAll`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getVendorType(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getVendorType`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getVendorPage(vendorPageROPageInfo, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/getVendorPage`, vendorPageROPageInfo, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  addVendor(vendorAddRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/vendor/add`, vendorAddRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  updateVendor(vendorUpdateRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/vendor/update`, vendorUpdateRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  deleteVendor(vendorDeleteRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/vendor/delete`, vendorDeleteRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getVendorTypeVendor(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getVendorTypeVendor`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  importVendor(uploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/vendor/importVendor`, uploadedRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default vendorRequest;
