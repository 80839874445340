import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, getCurrentInstance, onMounted, watchEffect, shallowRef, h } from "vue";
import dayjs from "dayjs";
import SvgIcon from "../components/SvgIcon.vue";
import FolderCard from "@/components/FolderCard.vue";
import MyCalendar from "@/components/MyCalendar.vue";
import util from "@/util/util";
import { genFileId } from "element-plus";
import { useRouter } from "vue-router";
import permission from "@/util/permission";
import "vue-cropper/dist/index.css";
import { VueCropper } from "vue-cropper";
import { DownCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default defineComponent({
  name: "ActivityCreate",
  components: {
    SvgIcon,
    FolderCard,
    MyCalendar,
    VueCropper,
    DownCircleOutlined
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRouter();
    let activityId = route.currentRoute.value.params.activityId;
    const getOrganizationUser = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.organizationRequest.getOrganizationUser(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getVendorTypeVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorTypeVendor(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getActivity = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivity(activityId, true).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202154") {
              resolve(res.result.data);
            } else {
              ElNotification({
                title: "失败",
                message: res.result.promptMsg,
                type: "error",
                duration: 6000
              });
              resolve(null);
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 6000
            });
            resolve(null);
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getActivityWeek = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivityWeek(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getActivityCategory = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivityCategory(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getActivityTag = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivityTag(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const activityLogoDialogVisible = ref(false);
    const logoCropper = ref(null);
    const activityLogoCropper = reactive({
      img: null,
      size: 1,
      full: true,
      outputType: "png",
      canMove: false,
      fixedBox: false,
      original: false,
      canMoveBox: true,
      autoCrop: true,
      centerBox: true,
      autoCropWidth: 768,
      autoCropHeight: 432,
      infoTrue: true,
      high: true,
      max: 99999,
      fixed: true,
      fixedNumber: [16, 9],
      limitMinSize: [768, 432]
    });
    const uploadActivityLogo = ref(null);
    const uploadActivityLogoData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      file: {
        url: null
      }
    });
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    const handleActivityLogoOnChange = (file, files) => {
      if (activityLogoDialogVisible.value == false) {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = e => {
            activityLogoCropper.img = e.target.result;
            resolve();
            activityLogoDialogVisible.value = true;
          };
          reader.readAsDataURL(file.raw);
        });
      }
    };
    const handleActivityLogoBeforeUpload = async file => {
      uploadActivityLogoData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadActivityLogoData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadActivityLogoData.signatureData.dir + fullName;
      uploadActivityLogoData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadActivityLogoData.signatureData.policy,
        OSSAccessKeyId: uploadActivityLogoData.signatureData.accessId,
        signature: uploadActivityLogoData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadActivityLogoData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleActivityLogoOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploaded = {
          fileName: uploadActivityLogoData.data.name,
          uploadedName: uploadActivityLogoData.data.key
        };
        activityData.activityFormData.logoUploaded = uploaded;
        const reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = e => {
          file.url = e.target.result;
          uploadActivityLogoData.file = file;
          activityLogoDialogVisible.value = false;
        };
      }
    };
    const handleActivityLogoExceed = files => {
      uploadActivityLogo.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadActivityLogo.value.handleStart(file);
      // uploadActivityLogo.value.submit();
    };

    const activityData = reactive({
      activityFormData: {
        activityName: "",
        activityWeekId: "",
        activityCategoryId: 1,
        activityYear: "",
        activityTags: [],
        activityDateValue: null,
        activityBegin: null,
        activityEnd: null,
        activityDescription: null,
        logoUploaded: null,
        activityManagers: [],
        activityVendors: []
      },
      activityFormRules: {
        activityName: [{
          required: true,
          message: "店铺名称必须填写",
          trigger: "blur"
        }],
        activityTags: [{
          required: true,
          message: "活动标签必须选择",
          trigger: "blur"
        }]
      },
      selectedOrganizationId: null,
      organizationTreeData: [],
      organizationTreeRapidData: {},
      // organizationData: [],
      organizationProps: {
        label: "organizationName",
        value: "organizationId"
      },
      organizationUserData: [],
      activityManagersProps: {
        label: "name",
        value: "id"
      },
      vendorTypeVendorData: [],
      vendorTypeProps: {
        label: "vendorTypeNameZh",
        value: "vendorTypeId"
      },
      vendorProps: {
        label: "vendorName",
        value: "vendorId"
      },
      activityTagData: [],
      activityTagFastData: [],
      activityTagCacheData: [],
      activityTypeProps: {
        label: "activityTagNameZh",
        value: "activityTagId"
      },
      uploadFiles: [],
      tagDialogVisible: false,
      tagDelete: false,
      tagSearchKey: null,
      activityWeekList: [],
      activityCategoryList: [],
      yearsArray: []
    });
    const activityForm = ref(null);
    onMounted(async () => {
      let organizationUserList = await getOrganizationUser();
      debugger;
      if (organizationUserList == null) {
        return;
      }
      // activityData.organizationData.push(...organizationUserList);

      activityData.organizationTreeRapidData = {};
      for (let organization of organizationUserList) {
        if (Array.isArray(organization.users)) {
          for (let user of organization.users) {
            user.name = user.lastName + " " + user.firstName;
          }
        }
        activityData.organizationTreeRapidData[organization.organizationId] = organization;
        if (organization.depth == 2) {
          activityData.organizationTreeData.push(organization);
        } else {
          let parentList = organization.path.split("/");
          let parentId = parentList[parentList.length - 1];
          if (activityData.organizationTreeRapidData[parentId].children == null) {
            activityData.organizationTreeRapidData[parentId].children = [];
          }
          activityData.organizationTreeRapidData[parentId].children.push(organization);
        }
      }
      activityData.organizationUserData.push(...organizationUserList);

      ///填数据
      let activityVO = null;
      if (activityId != null && activityId != "") {
        activityVO = await getActivity(activityId);
        activityData.activityFormData.activityName = activityVO.activityName;
        activityData.activityFormData.activityTagId = activityVO.activityTagId;
        activityData.activityFormData.activityBegin = activityVO.activityBegin;
        activityData.activityFormData.activityEnd = activityVO.activityEnd;
        activityData.activityFormData.activityDescription = activityVO.activityDescription;
        activityData.activityFormData.activityTags = [];
        activityData.activityFormData.activityWeekId = activityVO.activityWeekId;
        activityData.activityFormData.activityCategoryId = activityVO.activityCategoryId;
        activityData.activityFormData.activityYear = activityVO.activityYear;
        if (activityVO.tags != null && activityVO.tags.length > 0) {
          for (let item of activityVO.tags) {
            activityData.activityFormData.activityTags.push(item.activityTagId);
          }
        }
        activityData.activityFormData.activityDateValue = [];
        activityData.activityFormData.activityDateValue.push(activityVO.activityBegin);
        activityData.activityFormData.activityDateValue.push(activityVO.activityEnd);
        uploadActivityLogoData.file.url = activityVO.logoOssUploadedName;
        activityData.activityFormData.logoUploaded = {
          fileName: activityVO.logoFileName,
          uploadedName: activityVO.logoUploadedName
        };
        if (activityVO.uploaded != null) {
          for (let uploaded of activityVO.uploaded) {
            activityData.uploadFiles.push({
              fileName: uploaded.fileName,
              uploadedName: uploaded.uploadedName,
              uploadedOssName: uploaded.uploadedOssName,
              date: formatCurrentDate(new Date(uploaded.uploadedTime))
            });
          }
        }
      }
      if (activityVO != null && activityVO.activityManagers != null && activityVO.activityManagers.length > 0) {
        for (let activityManagers of activityVO.activityManagers) {
          let findIndex = activityData.organizationUserData.findIndex(element => element.organizationId == activityManagers.organizationId);
          if (findIndex != -1) {
            let item = activityData.organizationUserData[findIndex];
            let organization = {};
            findIndex = activityData.activityFormData.activityManagers.findIndex(element => element.organizationId == activityManagers.organizationId);
            if (findIndex != -1) {
              organization = activityData.activityFormData.activityManagers[findIndex];
            } else {
              organization = {
                organizationId: item.organizationId,
                organizationName: item.organizationName,
                userData: [],
                users: []
              };
              if (item.users != null) {
                organization.userData.push(...item.users);
              }
              activityData.activityFormData.activityManagers.push(organization);
              activityData.organizationTreeRapidData[organization.organizationId].disabled = true;

              // findIndex = activityData.organizationData.findIndex(
              //   (element) =>
              //     element.organizationId == organization.organizationId
              // );
              // if (findIndex != -1) {
              //   activityData.organizationData.splice(findIndex, 1);
              // }
            }

            organization.users.push({
              userId: activityManagers.userId,
              managedArea: activityManagers.managedArea
            });
          }
        }
        // if (activityData.organizationData.length > 0) {
        //   activityData.selectedOrganizationId =
        //     activityData.organizationData[0].organizationId;
        // }
      }

      let vendorTypeVendorList = await getVendorTypeVendor();
      if (vendorTypeVendorList == null) {
        return;
      }
      activityData.vendorTypeVendorData.push(...vendorTypeVendorList);
      if (activityVO != null && activityVO.vendors != null && activityVO.vendors.length > 0) {
        for (let vendors of activityVO.vendors) {
          let vendorItem = {
            vendorTypeId: vendors.vendorTypeId,
            vendorId: vendors.vendorId,
            vendorData: []
          };
          let findIndex = activityData.vendorTypeVendorData.findIndex(element => element.vendorTypeId == vendors.vendorTypeId);
          if (findIndex != -1) {
            vendorItem.vendorData.length = 0;
            vendorItem.vendorData.push(...activityData.vendorTypeVendorData[findIndex].vendors);
          }
          activityData.activityFormData.activityVendors.push(vendorItem);
        }
      } else {
        activityData.activityFormData.activityVendors.push({
          vendorTypeId: null,
          vendorId: null,
          vendorData: []
        });
      }
      let activityWeekList = await getActivityWeek();
      if (activityWeekList == null) {
        return;
      }
      activityData.activityWeekList = activityWeekList;
      let activityCategoryList = await getActivityCategory();
      if (activityCategoryList == null) {
        return;
      }
      activityData.activityCategoryList = activityCategoryList;
      let currentYear = new Date().getFullYear();
      let yearsArray = [];
      for (let i = -2; i <= 1; i++) {
        yearsArray.push(currentYear + i);
      }
      activityData.yearsArray = yearsArray.reverse();
      getActivityTagData();
    });
    const getActivityTagData = async () => {
      let activityTagList = await getActivityTag();
      if (activityTagList == null) {
        return;
      }
      activityData.activityTagData.length = 0;
      activityData.activityTagData.push(...activityTagList);
      activityData.activityTagFastData = getRandomItems(activityTagList);
    };
    const addOrganizationManagersClick = () => {
      if (activityData.selectedOrganizationId == null) {
        return;
      }
      let item = activityData.organizationTreeRapidData[activityData.selectedOrganizationId];
      if (item.disabled === true) {
        ElNotification({
          title: "错误",
          message: "不能重复添加",
          type: "error",
          duration: 3000
        });
        return;
      }
      let organization = {
        organizationId: item.organizationId,
        organizationName: item.organizationName,
        userData: [],
        users: []
      };
      if (item.users != null) {
        organization.userData.push(...item.users);
      }
      organization.users.push({
        userId: null,
        managedArea: null
      });
      item.disabled = true;
      activityData.activityFormData.activityManagers.push(organization);

      // let findIndex = activityData.organizationData.findIndex(
      //   (element) =>
      //     element.organizationId == activityData.selectedOrganizationId
      // );
      // if (findIndex != -1) {
      //   let item = activityData.organizationData[findIndex];
      //   let organization = {
      //     organizationId: item.organizationId,
      //     organizationName: item.organizationName,
      //     userData: [],
      //     users: [],
      //   };
      //   if (item.users != null) {
      //     organization.userData.push(...item.users);
      //   }
      //   organization.users.push({ userId: null, managedArea: null });
      //   activityData.activityFormData.activityManagers.push(organization);
      //   activityData.organizationData.splice(findIndex, 1);
      //   if (activityData.organizationData.length > 0) {
      //     activityData.selectedOrganizationId =
      //       activityData.organizationData[0].organizationId;
      //   }
      // }
    };

    const decreaseOrganizationManagersClick = activityManagers => {
      ElMessageBox.confirm("是否要删除[" + activityManagers.organizationName + "]", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        activityData.activityFormData.activityManagers = activityData.activityFormData.activityManagers.filter(obj => obj !== activityManagers);
        let item = activityData.organizationTreeRapidData[activityManagers.organizationId];
        item.disabled = false;

        // let findIndex = activityData.organizationUserData.findIndex(
        //   (element) =>
        //     element.organizationId == activityManagers.organizationId
        // );
        // if (findIndex != -1) {
        //   activityData.organizationData.push(
        //     activityData.organizationUserData[findIndex]
        //   );
        // }
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const decreaseManagersClick = (activityManagers, user) => {
      ElMessageBox.confirm("是否要删除版块[" + user.managedArea + "]", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        activityManagers.users = activityManagers.users.filter(obj => obj !== user);
        if (activityManagers.users.length == 0) {
          activityData.activityFormData.activityManagers = activityData.activityFormData.activityManagers.filter(obj => obj !== activityManagers);
          let item = activityData.organizationTreeRapidData[activityManagers.organizationId];
          item.disabled = false;
          // let findIndex = activityData.organizationUserData.findIndex(
          //   (element) =>
          //     element.organizationId == activityManagers.organizationId
          // );
          // if (findIndex != -1) {
          //   activityData.organizationData.push(
          //     activityData.organizationUserData[findIndex]
          //   );
          // }
        }
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const addManagersClick = users => {
      users.push({
        userId: null,
        managedArea: null
      });
    };
    const vendorTypeChange = val => {
      let findIndex = activityData.vendorTypeVendorData.findIndex(element => element.vendorTypeId == val.vendorTypeId);
      if (findIndex != -1) {
        val.vendorId = null;
        val.vendorData.length = 0;
        val.vendorData.push(...activityData.vendorTypeVendorData[findIndex].vendors);
      }
    };
    const addVendorClick = () => {
      activityData.activityFormData.activityVendors.push({
        vendorTypeId: null,
        vendorId: null,
        vendorData: []
      });
    };
    const decreaseVendorClick = vendor => {
      ElMessageBox.confirm("是否要删除供应商", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        activityData.activityFormData.activityVendors = activityData.activityFormData.activityVendors.filter(obj => obj !== vendor);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const uploadFile = ref(null);
    const uploadFilePopoverVisible = ref(false);
    const uploadFileData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadFilePopover = () => {
      uploadFile.value.clearFiles();
      uploadFilePopoverVisible.value = true;
    };
    const handleFileBeforeUpload = async file => {
      uploadFileData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadFileData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadFileData.signatureData.dir + fullName;
      uploadFileData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadFileData.signatureData.policy,
        OSSAccessKeyId: uploadFileData.signatureData.accessId,
        signature: uploadFileData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadFileData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleFileExceed = files => {
      uploadFile.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadFile.value.handleStart(file);
      uploadFile.value.submit();
    };
    const handleFileOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploaded = {
          fileName: uploadFileData.data.name,
          uploadedName: uploadFileData.data.key,
          date: formatCurrentDate(new Date())
        };
        activityData.uploadFiles.push(uploaded);
      }
    };
    const formatCurrentDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}.${month}.${day}`;
    };

    // const confirmSaveActivity = () => {
    //   activityForm.value.validate((valid, fields) => {
    //     if (valid) {
    //       activityData.activityFormData.activityBegin =
    //         activityData.activityFormData.activityDateValue[0];
    //       activityData.activityFormData.activityEnd =
    //         activityData.activityFormData.activityDateValue[1];

    //       const activityRO = {
    //         activityName: activityData.activityFormData.activityName,
    //         activityTags: activityData.activityFormData.activityTags,
    //         activityBegin: activityData.activityFormData.activityBegin,
    //         activityEnd: activityData.activityFormData.activityEnd,
    //         activityDescription:
    //           activityData.activityFormData.activityDescription,
    //         logoUploaded: activityData.activityFormData.logoUploaded,
    //         activityManagers: [],
    //         vendors: [],
    //         uploaded: activityData.uploadFiles,
    //       };

    //       if (
    //         activityData.activityFormData.activityManagers != null &&
    //         activityData.activityFormData.activityManagers.length > 0
    //       ) {
    //         for (let activityManagers of activityData.activityFormData
    //           .activityManagers) {
    //           if (
    //             activityManagers.users != null &&
    //             activityManagers.users.length > 0
    //           ) {
    //             for (let user of activityManagers.users) {
    //               if (
    //                 user.userId != null &&
    //                 user.managedArea != null &&
    //                 user.managedArea != ""
    //               ) {
    //                 user.organizationId = activityManagers.organizationId;
    //                 activityRO.activityManagers.push(user);
    //               }
    //             }
    //           }
    //         }
    //       }

    //       if (
    //         activityData.activityFormData.activityVendors != null &&
    //         activityData.activityFormData.activityVendors.length > 0
    //       ) {
    //         for (let activityVendors of activityData.activityFormData
    //           .activityVendors) {
    //           if (
    //             activityVendors.vendorId != null &&
    //             activityVendors.vendorTypeId != null
    //           ) {
    //             activityRO.vendors.push({
    //               vendorId: activityVendors.vendorId,
    //             });
    //           }
    //         }
    //       }

    //       console.log(activityRO);
    //       if (activityId != null && activityId != "") {
    //         activityRO.activityId = activityId;
    //         updateActivity(activityRO);
    //       } else {
    //         addActivity(activityRO);
    //       }
    //     }
    //   });
    // };

    const confirmSaveActivity = async () => {
      try {
        // 使用 Promise 进行表单验证
        const valid = await activityForm.value.validate();

        // 验证通过后的逻辑
        activityData.activityFormData.activityBegin = activityData.activityFormData.activityDateValue[0];
        activityData.activityFormData.activityEnd = activityData.activityFormData.activityDateValue[1];
        const activityRO = {
          activityName: activityData.activityFormData.activityName,
          activityWeekId: activityData.activityFormData.activityWeekId,
          activityCategoryId: activityData.activityFormData.activityCategoryId,
          activityYear: activityData.activityFormData.activityYear,
          activityTags: activityData.activityFormData.activityTags,
          activityBegin: activityData.activityFormData.activityBegin,
          activityEnd: activityData.activityFormData.activityEnd,
          activityDescription: activityData.activityFormData.activityDescription,
          logoUploaded: activityData.activityFormData.logoUploaded,
          activityManagers: [],
          vendors: [],
          uploaded: activityData.uploadFiles
        };
        if (activityData.activityFormData.activityManagers != null && activityData.activityFormData.activityManagers.length > 0) {
          for (let activityManagers of activityData.activityFormData.activityManagers) {
            if (activityManagers.users != null && activityManagers.users.length > 0) {
              for (let user of activityManagers.users) {
                if (user.userId != null && user.managedArea != null && user.managedArea != "") {
                  user.organizationId = activityManagers.organizationId;
                  activityRO.activityManagers.push(user);
                }
              }
            }
          }
        }
        if (activityData.activityFormData.activityVendors != null && activityData.activityFormData.activityVendors.length > 0) {
          for (let activityVendors of activityData.activityFormData.activityVendors) {
            if (activityVendors.vendorId != null && activityVendors.vendorTypeId != null) {
              activityRO.vendors.push({
                vendorId: activityVendors.vendorId
              });
            }
          }
        }
        console.log(activityRO);
        if (activityId != null && activityId != "") {
          activityRO.activityId = activityId;
          updateActivity(activityRO);
        } else {
          addActivity(activityRO);
        }
      } catch (error) {
        // 如果验证失败，这里可以处理错误
        console.error("表单验证失败：", error);
      }
    };
    const addActivity = async activityAddRO => {
      proxy.$api.activityRequest.addActivity(activityAddRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202153") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            route.push({
              path: "/activity-browser"
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const updateActivity = async activityUpdateRO => {
      proxy.$api.activityRequest.updateActivity(activityUpdateRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202155") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            route.push({
              path: "/activity-browser"
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const changeDate = date => {
      activityData.activityFormData.activityBegin = date.startDate;
      activityData.activityFormData.activityEnd = date.endDate;
    };
    const removeFile = uploaded => {
      ElMessageBox.confirm("是否要删除文件" + uploaded.fileName, "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        activityData.uploadFiles = activityData.uploadFiles.filter(obj => obj !== uploaded);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const activityDatePrefix = shallowRef({
      render() {
        return h("div", {
          style: {
            fontSize: "14px",
            fontStyle: "normal",
            display: "flex",
            alignItems: "center"
          }
        }, [h("div", "活动时间"), h("div", {
          style: {
            width: "1px",
            height: "20px",
            marginLeft: "5px",
            marginRight: "5px",
            backgroundColor: "black"
          }
        }), h("div", "开始")]);
      }
    });
    const getRandomItems = array => {
      if (array.length <= 3) {
        return array;
      }
      const numberOfItems = Math.floor(Math.random() * 2) + 3; // 随机选择3或4
      let extractedItems = [];
      for (let i = 0; i < numberOfItems; i++) {
        let index = Math.floor(Math.random() * array.length);
        extractedItems.push(array[index]);
        array.splice(index, 1);
      }
      return extractedItems;
    };
    const fastTagClick = item => {
      if (activityData.activityFormData.activityTags != null) {
        let findIndex = activityData.activityFormData.activityTags.findIndex(element => element == item.activityTagId);
        if (findIndex == -1) {
          activityData.activityFormData.activityTags.push(item.activityTagId);
        }
      } else {
        activityData.activityFormData.activityTags = [];
        activityData.activityFormData.activityTags.push(item.activityTagId);
      }
    };
    const headerCellClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = insTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    const tagTable = ref(null);
    const editRowEvent = row => {
      const $table = tagTable.value;
      if ($table) {
        $table.setEditRow(row);
      }
    };
    const oepnTagEditDialog = () => {
      activityData.activityTagCacheData.length = 0;
      activityData.activityTagCacheData.push(...util.clone(activityData.activityTagData));
      activityData.tagDialogVisible = true;
      activityData.tagDelete = false;
    };
    const addTag = async () => {
      const $table = tagTable.value;
      if ($table) {
        const record = {
          activityTagId: null,
          activityTagNameZh: null,
          activityTagNameEn: null
        };
        const {
          row: newRow
        } = await $table.insertAt(record, -1);
        await $table.setEditCell(newRow, "activityTagNameZh");
      }
    };
    const updateActivityTag = async updateActivityTagRO => {
      proxy.$api.activityRequest.updateActivityTag(updateActivityTagRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202171") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            getActivityTagData();
            activityData.tagDialogVisible = false;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const deleteActivityTag = async deleteActivityTagRO => {
      proxy.$api.activityRequest.deleteActivityTag(deleteActivityTagRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202172") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            getActivityTagData();
            activityData.tagDialogVisible = false;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const confirmTagDialog = () => {
      const $table = tagTable.value;
      var insertRecords = $table.getInsertRecords();
      let tagList = [];
      if (activityData.tagDelete == true) {
        const $table = tagTable.value;
        if ($table) {
          const selectTags = $table.getCheckboxRecords();
          for (let tag of selectTags) {
            if (tag.activityTagId != null) {
              tagList.push(tag);
            }
          }
          if (tagList.length > 0) {
            const deleteActivityTagRO = {
              activityTags: tagList
            };
            deleteActivityTag(deleteActivityTagRO);
          }
        }
      } else {
        if (activityData.activityTagCacheData.length > 0) {
          for (let tag of activityData.activityTagCacheData) {
            if (tag.activityTagId == null) {
              if (tag.activityTagNameZh != null && tag.activityTagNameZh != "" && activityData.tagDelete == false) {
                tagList.push(tag);
              }
            } else {
              if (tag.activityTagNameZh != null && tag.activityTagNameZh != "") {
                let findIndex = activityData.activityTagData.findIndex(element => element.activityTagId == tag.activityTagId);
                if (findIndex != -1) {
                  if (tag.activityTagNameZh != activityData.activityTagData[findIndex].activityTagNameZh) {
                    tagList.push(tag);
                  }
                }
              }
            }
          }
        }
        if (insertRecords.length > 0) {
          for (let tag of insertRecords) {
            if (tag.activityTagNameZh != null && tag.activityTagNameZh != "") {
              tagList.push(tag);
            }
          }
        }
        if (tagList.length > 0) {
          const updateActivityTagRO = {
            activityTags: tagList
          };
          updateActivityTag(updateActivityTagRO);
        }
      }
    };
    const searchEvent = () => {
      const filterVal = String(activityData.tagSearchKey).trim().toLowerCase();
      if (filterVal) {
        const filterRE = new RegExp(filterVal, "gi");
        const searchProps = ["activityTagNameZh"];
        const rest = activityData.activityTagData.filter(item => searchProps.some(key => String(item[key]).toLowerCase().indexOf(filterVal) > -1));
        activityData.activityTagCacheData = rest.map(row => {
          const item = Object.assign({}, row);
          searchProps.forEach(key => {
            item[key] = String(item[key]).replace(filterRE, match => match);
          });
          return item;
        });
      } else {
        activityData.activityTagCacheData = activityData.activityTagData;
      }
    };
    const getLogoCropBlobClick = () => {
      logoCropper.value.getCropBlob(async data => {
        let aTime = new Date().getTime();
        let fileName = aTime + "." + data.type.substr(6);
        let file = new window.File([data], fileName, {
          type: data.type
        });
        const resizedFile = await util.resizeImage(file, 768, 432);
        uploadActivityLogo.value.clearFiles();
        resizedFile.uid = genFileId();
        uploadActivityLogo.value.handleStart(resizedFile);
        uploadActivityLogo.value.submit();
      });
    };
    const realTime = data => {
      const imgWidth = parseFloat(data.img.width);
      const imgHeight = parseFloat(data.img.height);
      if (imgWidth != 0 && imgHeight != 0 && data.w != 0 && data.h != 0) {
        let scale = 1.0;
        let matches = data.img.transform.match(/scale\(([-+]?\d*\.?\d+(?:[eE][-+]?\d+)?)\)/);
        if (matches && matches.length > 1) {
          let number = parseFloat(matches[1]);
          scale = number;
        }
        const minWidthScrop = 768 * scale;
        const minHiehgtScrop = 432 * scale;
        activityLogoCropper.limitMinSize = [minWidthScrop, minHiehgtScrop];
        const wScale = data.w / (imgWidth * scale);
        const hScale = data.h / (imgHeight * scale);
        const trueWidth = wScale * imgWidth;
        const trueHeight = hScale * imgHeight;
      }
    };
    const goBack = () => {
      route.go(-1);
    };
    return {
      permission,
      getOrganizationUser,
      getVendorTypeVendor,
      activityData,
      activityForm,
      uploadActivityLogo,
      uploadActivityLogoData,
      getSignature,
      logoCropper,
      activityLogoCropper,
      activityLogoDialogVisible,
      handleActivityLogoOnChange,
      handleActivityLogoBeforeUpload,
      handleActivityLogoOnSuccess,
      handleActivityLogoExceed,
      addOrganizationManagersClick,
      decreaseOrganizationManagersClick,
      addManagersClick,
      decreaseManagersClick,
      vendorTypeChange,
      addVendorClick,
      decreaseVendorClick,
      openUploadFilePopover,
      uploadFile,
      uploadFilePopoverVisible,
      uploadFileData,
      handleFileExceed,
      handleFileBeforeUpload,
      handleFileOnSuccess,
      confirmSaveActivity,
      addActivity,
      changeDate,
      removeFile,
      activityDatePrefix,
      fastTagClick,
      headerCellClickEvent,
      tagTable,
      editRowEvent,
      oepnTagEditDialog,
      addTag,
      confirmTagDialog,
      searchEvent,
      getLogoCropBlobClick,
      realTime,
      goBack
    };
  }
});